import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentNav: "index",
    // baseUrl:'http://192.168.0.231:9091', // 本地
    // fileUrlHeader:'http://192.168.0.231:9091',  // 本地
    // baseUrl:'http://120.76.141.14:9090', // 测试
    // fileUrlHeader:'http://120.76.141.14:9090',  // 测试
    // baseUrl: "http://www.gism.com.cn/", // 正式
    // fileUrlHeader: "http://www.gism.com.cn/", // 正式
    baseUrl: "", // 线上
    fileUrlHeader: "", // 线上
  },
  mutations: {
    updateCurrentNav(state, val) {
      state.currentNav = val;
    },
  },
  actions: {},
  modules: {},
});
