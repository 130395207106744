import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/common.css'
import '@/assets/css/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow

Vue.use(ElementUI);
// 接口配置文件
import "@/api/index.js";

// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);

// 初始化vue-amap
// VueAMap.initAMapApiLoader({
//   // 高德的key
//   key: 'fc7cdbdf66df83e312c34e2a2befa445',
//   // 插件集合
//   plugin: ['AMap.Geolocation', 'AMap.Marker', 'AMap.ToolBar', 'AMap.Circle', 'AMap.PolyLine'],
//   uiVersion: '1.0',
//   // 高德 sdk 版本，默认为 1.4.4
//   v: '1.4.8'
// });
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
//查看大图
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)

Vue.config.productionTip = false
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
