import httpRequest from './httpRequest'

const request = {
    // 查询新闻列表 工控服务
    getInfoManageList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl("/fApi/infoManage/list"),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据新闻id获取新闻详细信息 工控服务
    getNewInfoManageDetail: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoManage/${params.infoId}/${params.infoColumn}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据栏目名称分页查询资讯列表 工控集团
    getNewList2: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/jccontent/channelName`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 查询工控集团资讯全部信息
    getNewList3: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/jccontent/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据新闻id获取新闻详细信息 工控集团
    getNewdetail: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/jccontent/${params.infoId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询【招标公告】列表
    getreportList: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTender/page`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    //  根据招标id获取【招标公告】详细信息
    getreportdetail: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTender/${params.tenderId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    //  分页查询【中标公告】列表
    getinfoTwList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTenderWinning/page`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    //  根据中标id获取【中标公告】详细信息
    getinfoTwDet: (params) => {
        // console.log(params)
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTenderWinning/${params.winningId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取推荐【招标公告】信息
    postInfoTenderTop: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTender/top`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取推荐【中标公告】信息
    postInfoTenderWinningTop: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoTenderWinning/top`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取验证码
    getChaimg: () => {
        return httpRequest({
            url: httpRequest.adornUrl(`/captchaImage`),
            method: "get",
        });
    },
    // 用户提交留言信息
    postaddInfo: (data) => {
        return httpRequest({
            url: httpRequest.adornUrl("/fApi/online/add"),
            method: "post",
            data: httpRequest.adornData(data)
        });
    },
    // 查询业务联系信息列表
    getInfoBusinessContactList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoBusinessContact/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据id获取业务联系信息详细信息
    getInfoBusinessContactById: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/infoBusinessContact/${params.contactId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询招聘管理列表
    getRecruitPage: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/recruit/page`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据id获取人才招聘详细信息
    getRecruitInfoById: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/recruit/${params.contactId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询企业信息列表
    getEnterprisePage: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/enterprise/page`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据id获取企业信息详细信息
    getEnterpriseById: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/enterprise/${params.enterpriseId}`),
            method: "get",
            // params: httpRequest.adornParams(params)
        });
    },
    // 获取所有案例栏目
    getCaseColumn: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/case/column`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据栏目id 分页查询案例管理列表
    getCasePage: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/case/page`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据id获取案例详细信息
    getCaseById: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/case/${params.caseId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询广告管理列表
    getImageAdvertisingList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/imageAdvertising/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取广告管理详细信息
    getImageAdvertisingListById: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/imageAdvertising/${params.advertisingId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    /*
    根据栏目类型分页查询图片管理列表
    */
    getImageManageList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/imageManage/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 根据id获取图片管理详细信息
    getImageManageListById: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/imageManage/${params.imageId}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询栏目列表
    getImageManageColumnList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/imageManage/column`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询友情链接列表
    getFriendlyLinkList: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/friendlyLink/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取友情链接详细信息
    getFriendlyLinkListById: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/friendlyLink/${params.id}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 分页查询服务领域列表
    getServiceTerritoryList: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/serviceTerritory/list`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 获取服务领域详细信息
    getServiceTerritoryById: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/serviceTerritory/${params.id}`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 关于我们-查询公司简介页面所需信息
    getAboutusProfile: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/enterprise/profile`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 查询关于我们-专业服务页面所需信息
    getAboutusProfessional: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/professional/team`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 查询关于我们-大事记页面所需信息
    getAboutusMemorabilia: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/memorabilia`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 查询关于我们-企业文化页面所需信息
    getAboutusEnterpriseCulture: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/enterprise/culture`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    // 查询官网-服务领域页面所需信息
    getServiceTerritory: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/service/territory`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },

    //党的建设-党建新闻页面
    getPartyNews: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/party/news`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    //党的建设-党建专栏页面
    getConstruction: (params) => {

        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/party/construction`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
    //党的建设-党建专栏页面
    getConstructionType: (params) => {
        return httpRequest({
            url: httpRequest.adornUrl(`/fApi/aboutus/party/construction/news`),
            method: "get",
            params: httpRequest.adornParams(params)
        });
    },
}
export default request;