var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"clear",class:[
      _vm.$store.state.currentNav == 'index' ? 'mainBody' : 'mainBodyA',
      'f-cont',
    ]},[_c('div',{staticClass:"fl fleft"},[_vm._m(0),_c('div',{staticClass:"fline colorfff fontsize18"},[_c('router-link',{attrs:{"to":"/contactus?key=FriendshipLink"}},[_vm._v("友情链接")]),_c('router-link',{attrs:{"to":"/contactus?key=Contactus"}},[_vm._v("联系我们")])],1),_vm._m(1)]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fphone colorfff"},[_c('img',{attrs:{"src":require("../../assets/images/index/phone.png")}}),_c('span',{staticClass:"fontsize24"},[_vm._v("020-88906327")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fbeian colorfff fontsize18"},[_c('span',[_vm._v("广州工控服务管理有限公司")]),_c('a',{staticClass:"fbeian_link",attrs:{"target":"_blank","href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v(" 版权所有 ICP号: 粤ICP备2021053251号 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fright fr clear"},[_c('div',{staticClass:"qrCode fontsize16"},[_c('span',[_vm._v("工控服务公众号")]),_c('img',{attrs:{"src":require("../../assets/images/common/fuwu.jpg")}})]),_c('div',{staticClass:"qrCode fontsize16"},[_c('span',[_vm._v("工控服务订阅号")]),_c('img',{attrs:{"src":require("../../assets/images/common/dingyue.jpg")}})])])
}]

export { render, staticRenderFns }