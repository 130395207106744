import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require("./import-" + process.env.NODE_ENV);

const routes = [
  {
    path: '/404',
    name: 'building',
    component:  _import("building"),
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/',
    name: 'index',
    component:  _import("index"),
    // component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/news',
    name: 'news',
    component:  _import("news/index"),
  },
  {
    path: '/news-details',
    name: 'news-details',
    component:  _import("news/details"),
  },
  {
    path: '/notice',
    name: 'notice',
    component:  _import("notice/index"),
  },
  {
    path: '/notice-details',
    name: 'notice-details',
    component:  _import("notice/details"),
  },
  // {
  //   path: '/party',
  //   name: 'party',
  //   component:  _import("party/index"),
  // },
  // {
  //   path: '/party-details',
  //   name: 'party-details',
  //   component:  _import("party/details"),
  // },
  {
    path: '/company',
    name: 'company',
    component:  _import("company"),
  },
  {
    path: '/contactus',
    name: 'contactus',
    component:  _import("contactus"),
  },
  {
    path: '/recruit',
    name: 'recruit',
    component:  _import("recruit"),
  },
  {
    path: '/serviceArea',
    name: 'serviceArea',
    component:  _import("serviceArea"),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
