<template>
  <div class="header">
    <div class="headerCon clear">
      <router-link to="/" class="fl header_left">
        <img src="../../assets/images/index/leftlogo.png" />
      </router-link>
      <ul class="fr navlist fontsize16 colorfff">
        <template v-for="(item, index) in navlist">
          <el-tooltip
            v-if="item.tips"
            :content="item.tips"
            placement="bottom"
            effect="light"
            :key="index"
          >
            <li :class="[currentNav == item.key ? 'on' : '']">
              <router-link :to="item.url">{{ item.text }}</router-link>
            </li>
          </el-tooltip>
          <li v-else :class="[currentNav == item.key ? 'on' : '']" :key="index">
            <!-- @click="OnClickNav(item.key)" -->
            <router-link :to="item.url">{{ item.text }}</router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { Tooltip } from "element-ui";
export default {
  data() {
    return {
      navlist: [
        {
          url: "/",
          text: "首页",
          key: "index",
          tips: "",
        },
        {
          url: "/company",
          text: "关于我们",
          key: "company",
          tips: "",
        },
        {
          url: "/news",
          text: "新闻动态",
          key: "news",
          tips: "",
        },
        {
          url: "/serviceArea?key=OperationAndMaintenance",
          text: "服务领域",
          key: "serviceArea",
          tips: "",
        },
        // {
        //     url: "/party",
        //     text: "党的建设",
        //     key: "party",
        //     tips: ""
        // },
        {
          url: "/notice?key=Bidding",
          text: "招标公告",
          key: "notice",
          tips: "",
        },
        {
          url: "/recruit",
          text: "人才招聘",
          key: "recruit",
          tips: "",
        },
        {
          url: "/contactus",
          text: "联系我们",
          key: "contactus",
          tips: "",
        },
      ],
    };
  },
  computed: {
    currentNav: {
      get() {
        return this.$store.state.currentNav;
      },
      set(val) {
        return this.$store.commit("updateCurrentNav", val);
      },
    },
  },
  mounted() {
    // console.log(this.$route.name);
  },
  methods: {
    OnClickNav(val) {
      this.currentNav = val;
      // console.log(this.currentNav==val)
      // console.log(this.currentNav);
      // console.log(val);
    },
  },
  components: {
    Tooltip,
  },
  watch: {
    // '$route' (res) {
    // 	console.log('路由变化')
    // 	console.log(res)
    // }
    $route: {
      handler(newName, oldName) {
        let navData = {
          building: "building",
          contactus: "contactus",
          index: "index",
          party: "party",
          "party-details": "party",
          news: "news",
          company: "company",
          "news-details": "news",
          serviceArea: "serviceArea",
          recruit: "recruit",
          notice: "notice",
          "notice-details": "notice",
        };
        this.currentNav = navData[newName.name];
        // this.$store.commit("updateCurrentNav", newName.name);
        // console.log(newName);
        // console.log(oldName);
      },
      immediate: true,
    },
  },
};
</script>
<style scoped lang="scss"></style>
