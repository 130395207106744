<template>
  <div id="app">
    <gknav />
    <router-view class="cont" />
    <gkfooter />
  </div>
</template>
<script>
import gknav from "@/components/common/gknav.vue";
import gkfooter from "@/components/common/gkfooter.vue";
export default {
  components: {
    gknav,
    gkfooter,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
