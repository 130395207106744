<template>
  <div class="footer">
    <!--  -->
    <div
      class=" clear"
      :class="[
        $store.state.currentNav == 'index' ? 'mainBody' : 'mainBodyA',
        'f-cont',
      ]"
    >
      <div class="fl fleft">
        <div class="fphone colorfff">
          <img src="../../assets/images/index/phone.png" />
          <span class="fontsize24">020-88906327</span>
        </div>
        <div class="fline colorfff fontsize18">
          <!-- <el-tooltip
                        content="正在建设中"
                        placement="top"
                        effect="light"
                    >
                    <router-link to="javascript:;">法律声明</router-link></el-tooltip> -->
          <!-- <el-tooltip
                        content="正在建设中"
                        placement="top"
                        effect="light"
                        ><router-link to="javascript:;">友情链接</router-link></el-tooltip
                    > -->
          <router-link to="/contactus?key=FriendshipLink">友情链接</router-link>
          <router-link to="/contactus?key=Contactus">联系我们</router-link>
        </div>
        <div class="fbeian colorfff fontsize18">
          <span>广州工控服务管理有限公司</span>
          <a class="fbeian_link" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
            版权所有 ICP号: 粤ICP备2021053251号
          </a>
          <!-- <div class="fbeian_com">广州化工交易中心有限公司提供服务技术支持</div> -->
        </div>
      </div>
      <div class="fright fr clear">
        <div class="qrCode fontsize16">
          <span>工控服务公众号</span>
          <img src="../../assets/images/common/fuwu.jpg" />
        </div>
        <div class="qrCode fontsize16">
          <span>工控服务订阅号</span>
          <img src="../../assets/images/common/dingyue.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Tooltip } from "element-ui";
export default {};
</script>
<style scoped lang="scss">
.footer {
  width: 100%;
  height: 10.42vw;
  background: url("../../assets/images/index/footerbg.png") no-repeat;
  background-size: 100% 100%;
  padding: 2.08vw 0;
  box-sizing: border-box;
  overflow: hidden;
  .f-cont {
    position: relative;
    .fleft {
      // width: 28.13vw;

      .fbeian {
        margin-top: 1.04vw;
        position: relative;
        width: 100%;
        .fbeian_link {
          display: inline-block;
        }
        .fbeian_com {
          float: right;
        }
      }

      .fphone {
        text-align: left;
        display: inline-block;
        img {
          display: inline-block;
          width: 1.56vw;
          margin-right: 0.52vw;
          vertical-align: top;
        }
      }

      .fline {
        margin-top: 1.04vw;

        a {
          display: inline-block;
          padding: 0 1.04vw;
          border-right: 0.05vw solid #ffffff;
          &:last-child {
            border-right: none;
          }
          &:nth-child(1) {
            padding-left: 0;
          }
        }
      }
    }
    .fright {
      // width: 14.58vw;
      position: absolute;
      top: 0;
      right: 0;
      .qrCode {
        float: left;
        margin: 0 1.04vw;
        text-align: center;

        span {
          display: block;
          font-size: 0.73vw;
          line-height: 1.04vw;
          padding: 0 0 0.52vw 0;
          color: #ffffff;
        }

        img {
          display: inline-block;
          width: 5.21vw;
          // width: 100%;
        }
      }
    }
  }
}
@media (min-width: 1920px) {
  .footer {
    width: 100%;
    height: 200px;
    background: url("../../assets/images/index/footerbg.png") no-repeat;
    background-size: 100% 100%;
    padding: 40px 0;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .f-cont {
      position: relative;

      .fleft {
        // width: 540px;

        .fbeian {
          margin-top: 20px;
          position: relative;
          width: 100%;
          .fbeian_link {
            display: inline-block;
          }
          .fbeian_com {
            float: right;
          }
        }

        .fphone {
          text-align: left;
          display: inline-block;
          img {
            display: inline-block;
            width: 30px;
            margin-right: 10px;
            vertical-align: top;
          }
        }

        .fline {
          margin-top: 20px;

          a {
            display: inline-block;
            padding: 0 20px;
            border-right: 1px solid #ffffff;
            &:last-child {
              border-right: none;
            }
            &:nth-child(1) {
              padding-left: 0;
            }
          }
        }
      }
      .fright {
        // width: 280px;
        position: absolute;
        top: 0;
        right: 0;
        .qrCode {
          float: left;
          margin: 0 20px;
          text-align: center;

          span {
            display: block;
            font-size: 14px;
            line-height: 20px;
            padding: 0 0 10px 0;
            color: #ffffff;
          }

          img {
            // width: 100%;
            display: inline-block;
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
